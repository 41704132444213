import React, { lazy, Suspense, useEffect, useRef } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import JwtAuthService from "services/JwtAuthService";
import MenuService from "services/MenuService";

export const AppViews = () => {
	let intervalId = useRef(null);

	const populateMenu = async () => {
		try {
			if (JwtAuthService.isLogged()) {
				await MenuService.populateMenuData();
			}
		} catch (e) {}

		intervalId.current = setInterval(() => {
			MenuService.populateMenuData();
		}, 30000);
	};

	useEffect(() => {
		populateMenu();

		return () => {
			clearInterval(intervalId.current);
		};
	}, []);

	return (
		<Suspense fallback={<Loading cover="content" />}>
			<Switch>
				<Route
					path={`${APP_PREFIX_PATH}/dashboard`}
					component={lazy(() => import(`./dashboard`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-usuarios`}
					component={lazy(() => import(`./lista-usuarios`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/usuario/:id`}
					component={lazy(() => import(`./usuario`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/usuario`}
					component={lazy(() => import(`./usuario`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-pedidos`}
					component={lazy(() => import(`./lista-pedidos`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastro-pedido/:id`}
					component={lazy(() => import(`./cadastro-pedido`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastro-pedido`}
					component={lazy(() => import(`./cadastro-pedido`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-grupos-usuario`}
					component={lazy(() => import(`./lista-grupos-usuario`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-projetos`}
					component={lazy(() => import(`./lista-projetos`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-turmas/:idProjeto`}
					component={lazy(() => import(`./lista-turmas`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-alunos/:idTurma`}
					component={lazy(() => import(`./lista-alunos`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-livros-aluno/:id`}
					component={lazy(() => import(`./lista-livros-aluno`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/grupo-usuario/:id`}
					component={lazy(() => import(`./grupo-usuario`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/grupo-usuario`}
					component={lazy(() => import(`./grupo-usuario`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastro-professor/:id`}
					component={lazy(() => import(`./cadastro-professor`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastro-professor`}
					component={lazy(() => import(`./cadastro-professor`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastro-aluno/:idTurma/:id`}
					component={lazy(() => import(`./cadastro-aluno`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastro-aluno/:idTurma`}
					component={lazy(() => import(`./cadastro-aluno`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastro-turma/:idProjeto/:id`}
					component={lazy(() => import(`./cadastro-turma`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastro-turma/:idProjeto`}
					component={lazy(() => import(`./cadastro-turma`))}
				/>

				<Route
					path={`${APP_PREFIX_PATH}/cadastro-livro/:id`}
					component={lazy(() => import(`./cadastro-livro`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastro-livro`}
					component={lazy(() => import(`./cadastro-livro`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastro-projeto/:id`}
					component={lazy(() => import(`./cadastro-projeto`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cadastro-projeto`}
					component={lazy(() => import(`./cadastro-projeto`))}
				/>
				<Redirect
					from={`${APP_PREFIX_PATH}`}
					to={`${APP_PREFIX_PATH}/lista-projetos`}
				/>
			</Switch>
		</Suspense>
	);
};

export default React.memo(AppViews);
