export const PermissaoUsuarioEnum = {
	VISUALIZAR: "VISUALIZAR_USUARIO",
	CRIAR: "CRIAR_USUARIO",
	EXCLUIR: "EXCLUIR_USUARIO",
};

export const PermissaoGrupoUsuarioEnum = {
	VISUALIZAR: "VISUALIZAR_GRUPO_USUARIO",
	CRIAR: "CRIAR_GRUPO_USUARIO",
	EXCLUIR: "EXCLUIR_GRUPO_USUARIO",
};

export const PermissaoProjetoEnum = {
	VISUALIZAR: "VISUALIZAR_PROJETO",
	CRIAR: "CRIAR_PROJETO",
	EDITAR: "EDITAR_PROJETO",
	EXCLUIR: "EXCLUIR_PROJETO",
};

export const PermissaoTurmaEnum = {
	VISUALIZAR: "VISUALIZAR_TURMA",
	CRIAR: "CRIAR_TURMA",
	EDITAR: "EDITAR_TURMA",
	EXCLUIR: "EXCLUIR_TURMA",
};

export const PermissaoAlunoEnum = {
	VISUALIZAR: "VISUALIZAR_ALUNO",
	CRIAR: "CRIAR_ALUNO",
	EDITAR: "EDITAR_ALUNO",
	EXCLUIR: "EXCLUIR_ALUNO",
};

export const PermissaoPedidoEnum = {
	VISUALIZAR: "VISUALIZAR_PEDIDO",
	GERAR_ZIP: "GERAR_ZIP_PEDIDO",
	CRIAR: "CRIAR_PEDIDO",
	EDITAR: "EDITAR_PEDIDO",
	EXCLUIR: "EXCLUIR_PEDIDO",
}
