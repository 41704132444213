import {
// 	HomeOutlined,
	UserOutlined,
	ShopOutlined,
// 	UsergroupAddOutlined,
} from "@ant-design/icons";
import { ProjetoSVG } from "assets/svg/icon";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import {
	PermissaoPedidoEnum,
	// PermissaoGrupoUsuarioEnum,
	PermissaoProjetoEnum,
	PermissaoUsuarioEnum,
} from "enum/permissao-enum";

const dashBoardNavTree = [
	// {
	// 	key: "dashboard",
	// 	path: `${APP_PREFIX_PATH}/dashboard`,
	// 	title: "dashboard",
	// 	icon: HomeOutlined,
	// 	breadcrumb: true,
	// 	submenu: [],
	// },
	{
		key: "lista-projetos",
		path: `${APP_PREFIX_PATH}/lista-projetos`,
		title: "lista-projetos",
		icon: ProjetoSVG,
		breadcrumb: true,
		submenu: [],
		permissao: PermissaoProjetoEnum.VISUALIZAR,
	},
	{
		key: "lista-usuarios",
		path: `${APP_PREFIX_PATH}/lista-usuarios`,
		title: "lista-usuarios",
		icon: UserOutlined,
		// breadcrumb: true,
		submenu: [],
		permissao: PermissaoUsuarioEnum.VISUALIZAR,
	},
	{
		key: "lista-pedidos",
		path: `${APP_PREFIX_PATH}/lista-pedidos`,
		title: "lista-pedidos",
		icon: ShopOutlined,
		// breadcrumb: true,
		submenu: [],
		permissao: PermissaoPedidoEnum.VISUALIZAR,
	},
	// {
	// 	key: "lista-grupos-usuario",
	// 	path: `${APP_PREFIX_PATH}/lista-grupos-usuario`,
	// 	title: "lista-grupos-usuario",
	// 	icon: UsergroupAddOutlined,
	// 	breadcrumb: true,
	// 	submenu: [],
	// 	permissao: PermissaoGrupoUsuarioEnum.VISUALIZAR,
	// },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
